.main-container {
  display: flex;
  .main-content {
    position: relative;
    flex: 1;
    .header {
      height: 80px;
      background-color: #ffffff;
      border-bottom: 1px solid #ededed;
      padding: 0 1.5rem 0 2rem;
      .h-title {
        color: #241a2e;
        font-size: 20px;
        font-weight: bold;
      }
      .h-icon {
        padding-right: 0.5rem;
      }
      img {
        cursor: pointer;
      }
    }
    .content-body {
    }
  }
}
.tax-permits {
  display: flex;
  flex-wrap: wrap;
  .tax-permit-box {
    flex: 6;
    border-right: 1px solid #d8d8d8;
    min-height: 500px;
    .tab-box {
      min-height: 128px;
      padding: 0rem 1rem 0 1rem;
      border-bottom: 1px solid #d8d8d8;
      display: flex;
      flex-wrap: wrap;
      .tab-item {
        width: 80px;
        height: 128px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.8rem;
        cursor: pointer;
        &:hover {
          .tab-icon {
            background-color: #dec330;
          }
          .tab-text {
            color: #241a2e;
          }
        }
        &.active-tab {
          border-bottom: 4px solid #dec330;
          .tab-icon {
            background-color: #dec330;
          }
          .tab-text {
            color: #241a2e;
          }
        }
        .tab-icon {
          img {
            height: 32px;
          }
          height: 56px;
          width: 56px;
          border-radius: 12px;
          background-color: #e8e8e8;
        }
        .tab-text {
          color: #979598;
          text-align: center;
          font-size: 12px;
          font-weight: 500;
          padding-top: 0.5rem;
          height: 30px;
          width: 70px;
        }
      }
    }
    .tab-content {
      padding: 3rem 4rem;
      .form-box {
        .justify-between {
          justify-content: space-between;
        }
        .label {
          padding-bottom: 0.5rem;
          color: #241a2e;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 24px;
        }
        .custom-select {
          height: 43px;
          width: 100%;
          border-radius: 7px;
          background-color: #f8f8fa;
        }
        .form-field {
          border-bottom: 1px solid #e8e8e8;
          padding-bottom: 2rem;
          margin-bottom: 2rem;
        }
        .remove-btn {
          text-transform: uppercase;
          color: #777777;
          text-decoration: underline solid #777777;
          cursor: pointer;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.8px;
        }
        .mr-1 {
          margin-right: 1rem;
        }
      }
    }
  }
  .activity-box {
    flex: 3;
    min-width: 367px;
    //width: 520px;
    .act-head {
      padding: 1.5rem 1rem;
      .act-title {
        color: #241a2e;
        font-size: 16px;
        font-weight: bold;
      }
      .act-info {
        padding-left: 1rem;
        color: #a0a0a0;
        font-size: 16px;
        font-style: italic;
      }
    }
    .act-body {
      .act-item:last-child {
        border-bottom: unset !important;
      }
      .act-item {
        min-height: 142px;
        border-bottom: 1px solid #e3e3e3;
        padding: 1rem;
        .act-flex {
          display: flex;
          flex-wrap: wrap;
        }
        .flex-1 {
          flex: 1;
          padding: 0 0.5rem;
        }
        .act-detail {
          display: flex;
          .pb-5 {
            padding-bottom: 0.5rem;
          }
          .empty {
            height: 32px;
            width: 32px;
          }
        }
        .act-s-icon {
          height: 32px;
          width: 32px;
          border-radius: 8px;
          background-color: #f8f3d6;
        }
        .act-company {
          color: #241a2e;
          font-size: 16px;
          font-weight: 600;
          padding-top: 0.5rem;
        }
        .w-40 {
          width: 40%;
          color: #241a2e;
          font-size: 13px;
          font-weight: 600;
        }
        .w-60 {
          width: 60%;
          color: #241a2e;
          font-size: 13px;
        }
        .act-status {
          height: 32px;
          width: 115px;
          border-radius: 16px;
          background-color: #e9e9e9;
          color: #6a666d;
          font-size: 14px;
          font-style: italic;
          font-weight: 500;
        }
      }
    }
  }
}
