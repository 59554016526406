.main-container {
  display: flex;
  .sidebar {
    width: 245px;
    min-height: 100vh;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #241a2e;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    padding: 1rem 0;
    .MuiAccordionDetails-root {
      padding: 0 0 10px 10px !important;
    }
    .MuiPaper-root-MuiAccordion-root {
      &.Mui-expanded {
        margin: unset !important;
        .menu-text {
          color: #ffffff !important;
        }
      }
    }
    .MuiButtonBase-root-MuiAccordionSummary-root {
      &.Mui-expanded {
        min-height: auto !important;
      }
    }
    .MuiAccordionSummary-expandIconWrapper {
      margin-right: 12px;
    }
    .MuiSvgIcon-root {
      fill: #979598;
    }
    .MuiPaper-root-MuiAccordion-root {
      &::before {
        background-color: unset !important;
      }
      border-radius: 8px 0 0 8px;
      min-height: 48px;
      background-color: unset !important;
      box-shadow: unset !important;
      &:hover {
        background: linear-gradient(
          90deg,
          #dec330 0%,
          #dec330 68.69%,
          rgba(222, 195, 48, 0.3) 100%
        ) !important;
        color: unset !important;
      }
      .MuiButtonBase-root-MuiAccordionSummary-root {
        padding: unset !important;
      }
      .MuiAccordionSummary-content {
        margin: unset !important;
      }
      .menu-text {
        color: #979598;
        font-size: 15px;
        font-weight: 500;
        padding-left: 0.5rem;
      }

      .inner-menu {
        height: 32px;
        //opacity: 0.25;
        .act-btn {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: #dec330;
          margin: 0 12px 0 8px;
        }
        .hidden {
          visibility: hidden;
        }
        &:hover {
          border-radius: 8px 0 0 8px;
          background-color: rgba(180, 176, 183, 0.55);
        }
      }
      .menu-item {
        &:hover {
          background: unset !important;
        }
      }
    }
    .logo {
      padding: 0 0 0 1.5rem;
    }
    .portal {
      padding: 0.3rem 0 0 1.5rem;
      color: #979598;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 3.33px;
      text-transform: uppercase;
    }
    .menu-box {
      padding-left: 0.3rem;
      padding-top: 1rem;
      .menu-item {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0em 1rem;
        cursor: pointer;
        &:hover {
          //opacity: 0.25;
          border-radius: 8px 0 0 8px;
          background: linear-gradient(
            90deg,
            #dec330 0%,
            #dec330 68.69%,
            rgba(222, 195, 48, 0.3) 100%
          );
          .menu-text {
            color: #ffffff !important;
          }
        }
        .menu-icon {
          height: 24px;
          width: 24px;
        }
        .menu-text {
          color: #979598;
          font-size: 15px;
          font-weight: 500;
          padding-left: 0.5rem;
        }
      }
    }
  }
  .main-content {
    position: relative;
    flex: 1;
    .header {
      height: 80px;
      background-color: #ffffff;
      border-bottom: 1px solid #ededed;
      padding: 0 1.5rem 0 2rem;
      .h-title {
        color: #241a2e;
        font-size: 20px;
        font-weight: bold;
      }
      .h-icon {
        padding-right: 0.5rem;
      }
      img {
        cursor: pointer;
      }
    }
    .content-body {
    }
  }
}
