@import 'variables';

@font-face {
  font-family: 'FiraSans-Black';
  src: local('FiraSans-Black'),
    url(./../assets/fonts/FiraSans-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Bold';
  src: local('FiraSans-Bold'),
    url(./../assets/fonts/FiraSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-ExtraBold';
  src: local('FiraSans-ExtraBold'),
    url(./../assets/fonts/FiraSans-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-ExtraLight';
  src: local('FiraSans-ExtraLight'),
    url(./../assets/fonts/FiraSans-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Light';
  src: local('FiraSans-Light'),
    url(./../assets/fonts/FiraSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Medium';
  src: local('FiraSans-Medium'),
    url(./../assets/fonts/FiraSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Regular';
  src: local('FiraSans-Regular'),
    url(./../assets/fonts/FiraSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-SemiBold';
  src: local('FiraSans-SemiBold'),
    url(./../assets/fonts/FiraSans-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'FiraSans-Thin';
  src: local('FiraSans-Thin'),
    url(./../assets/fonts/FiraSans-Thin.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  font-family: 'FiraSans-Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5 !important;
}
.pointer {
  cursor: pointer;
}
.cancel-btn {
  height: 48px !important ;
  width: 170px !important;
  border-radius: 4px !important;
  background-color: #d1d1d1 !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}
.add-btn {
  height: 48px !important;
  width: 170px !important;
  border-radius: 4px !important;
  background-color: #dec330 !important;
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}
.d-flex-a {
  display: flex;
  align-items: center;
}
.d-flex-ja {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex-sa {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#root {
  height: inherit;
}

code {
  font-family: 'Fira Sans';
}

div,
h1,
h2,
h3,
a,
p,
h4,
h5,
h6,
ul,
li {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
.app-loader {
  width: 50%;
  height: 50%;
}
.flex-centering {
  display: flex;
  flex-direction: column;
  height: inherit;
  align-items: center;
  justify-content: center;
}

.center-content {
  justify-content: center;
}

.no-padding {
  padding: 0 !important;
}
.no-padding-horizontal {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.test {
  background-color: red;
}

/*
  * Mui form global css starts here
   */

/* .MuiFormHelperText-root {
  position: absolute;
  bottom: 0;
} */

button.MuiLink-root {
  &:focus {
    outline: 0;
  }
}

button {
  box-shadow: unset !important;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #b1b4be;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #b1b4be;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #b1b4be;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b1b4be;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b1b4be;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #b1b4be;
}

.file-drop {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}
.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 7%) !important;
}
#alert .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.file-drop-target {
  width: 100%;
}

// body {
//   background-color: transparent !important;
//   background: transparent !important;
// }

.window {
  transform: scale(0.96);
  box-shadow: 0px 2px 10px 0px #a0a0a04f;
  border-radius: 4px;
  overflow: hidden;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-center-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.window-margin {
  margin-right: 10px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.capitalize {
  text-transform: capitalize;
}
.full-width {
  width: 100%;
}
.content-body {
  padding-top: 5rem;
}
.MuiPaper-elevation {
  .MuiGrid-container {
    padding: 0px 16px !important;
  }
  .MuiTableCell-root {
    padding: 0px !important;
  }
  .MuiBox-root {
    .MuiGrid-container {
      padding: 16px 10px !important;
    }
  }
  padding: 16px 0px !important;
}
input,
select {
  &:focus-visible {
    border: unset !important;
    outline: unset !important;
  }
  &::placeholder {
    font-size: 14px !important;
  }
}
.custom-error {
  color: #da5a30 !important;
  font-size: 0.6875rem;
}
.MuiPaper-elevation {
  background-color: #ffffff !important;
  & .Mui-selected {
    background-color: blue !important;
  }
}
.MuiPickersDay-root {
  background-color: #fff !important;
  border: 1px solid lightgray !important;
}
.Mui-disabled {
  background-color: lightgray !important;
  border: 1px solid lightgray !important;
}
.MuiOutlinedInput-input {
  padding: 8.1px 1px !important;
}
table {
  th {
    padding: 0px 16px !important;
  }
  td {
    border-bottom: 1px solid #e8e8e8 !important;
  }
  tbody {
    background-color: #ffffff !important;
  }
}
.MuiOutlinedInput-notchedOutline {
  border: unset !important;
}
.MuiInput-root:before,
.MuiInput-root:after {
  border-bottom: unset !important;
}
.MuiInput-input {
  padding-right: unset !important;
  padding-top: unset !important;
  padding-bottom: unset !important;
}
.MuiOutlinedInput-notchedOutline {
  border: unset !important;
}

.admin-popclass {
  .MuiPaper-elevation {
    background-color: unset !important;
    box-shadow: unset !important;
    left: unset !important;
    right: 6.1vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
.role-popclass {
  .MuiPaper-elevation {
    background-color: unset !important;
    box-shadow: unset !important;
    left: unset !important;
    right: 7vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
.driver-profile {
  display: flex;
  flex-direction: column;
  flex: auto;
  .MuiPaper-elevation {
    padding: 0.4px 0px !important;
  }
  .MuiGrid-container {
    padding: 0px !important;
  }
  .headerplace {
    padding: 0.5rem 0px !important;
  }
}
.MuiTableCell-head {
  border-bottom: unset !important;
}
input,
textarea {
  padding-left: 0.5rem !important;
}
.filter {
  border-radius: 8px;
  width: 320px;
  .MuiPopover-paper {
    border-radius: 8px;
    width: 320px;
  }
  .add-btn {
    width: 100% !important;
  }
  .MuiPaper-elevation {
    background-color: #241a2e !important;
  }
}
.driver-popclass {
  .MuiPaper-elevation {
    background-color: unset !important;
    box-shadow: unset !important;
    left: unset !important;
    right: 6.4vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -7px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
.popclass {
  .MuiPaper-elevation {
    //padding: unset !important;
    left: unset !important;
    right: 5vw;
    overflow: visible;
    border-radius: 8px;
  }
  .arrow {
    width: 15px;
    height: 15px;
    background-color: #241a2e;
    position: absolute;
    right: 23px;
    top: -24px;
    transform: rotate(45deg);
  }
  .n-pop-btn {
    height: 32px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    transition: 0.5s;
    &:hover {
      background-color: #37414f;
    }
  }
  .a-pop-btn {
    height: 32px;
    color: #ffffff;
    font-family: 'FiraSans-Regular';
    cursor: pointer;
    font-size: 15px;
    background-color: #37414f;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
  }
}
