.main-content {
  position: relative;
  flex: 1;
  .header {
    height: 80px;
    background-color: #ffffff;
    border-bottom: 1px solid #ededed;
    padding: 0 1.5rem 0 2rem;
    .h-title {
      color: #241a2e;
      font-size: 20px;
      font-weight: bold;
    }
    .h-icon {
      padding-right: 0.5rem;
    }
    img {
      cursor: pointer;
    }
  }
  // .content-body {
  // }
}
